import { getRegionalisedBookingEngineUrl } from "./getRegionalisedBookingEngineUrl";

export const getBookingEngineManageBookingUrl = (
  localeCode: string,
  countryCode: string
): string => {
  const bookingEngineUrl = getRegionalisedBookingEngineUrl(
    localeCode,
    countryCode
  );
  return `${bookingEngineUrl}/booking_engine/manageBooking`;
};
