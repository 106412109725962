import { getCountryByCode } from "./getCountryByCode";
import { getDefaultCountry } from "./getDefaultCountry";
import { getLocaleByCode } from "./getLocaleByCode";

export const getRegionalisedBookingEngineUrl = (
  localeCode: string,
  countryCode: string
): string => {
  const bookingEngineBaseUrl = process.env.NUXT_PUBLIC_BOOKING_ENGINE_BASE_URL;
  let regionalisedBookingEnginePath = "";

  const locale = getLocaleByCode(localeCode);

  if (locale.distributeBookings) {
    const country = getCountryByCode(countryCode) || getDefaultCountry();
    regionalisedBookingEnginePath = `/bookings/${country.bookingEngineLocale}`;
  } else {
    regionalisedBookingEnginePath = `/bookings/${locale.bookingEngineLocale}`;
  }

  return `${bookingEngineBaseUrl}${regionalisedBookingEnginePath}`;
};
