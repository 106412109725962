<template>
  <h1>Manage booking redirect</h1>
</template>

<script lang="ts">
import Vue from "vue";
import { getBookingEngineManageBookingUrl } from "~/lib/utils/locale/getBookingEngineManageBookingUrl";
import { getCountryFromIncomingRequestOrDefault } from "~/lib/utils/locale/getCountryFromIncomingRequestOrDefault";

export default Vue.extend({
  middleware({ redirect, req, i18n }) {
    redirect(
      301,
      getBookingEngineManageBookingUrl(
        i18n.locale,
        getCountryFromIncomingRequestOrDefault(req)
      )
    );
  },
});
</script>
