import { countries } from "~~/config/countries";
import { Country } from "~~/lib/types/Country";

export const getCountryByCode = (countryCode: string): Country | undefined => {
  if (!countryCode) {
    return undefined;
  }

  const lowerCaseCountryCode = countryCode.toLowerCase();
  const country = countries.find(
    ({ iso2Code }: Country) => iso2Code === lowerCaseCountryCode
  );
  return country;
};
